import React, { Component } from "react";
import PropTypes from "prop-types";
var QRCode = require("qrcode.react");

class ShowCard extends Component {
  render() {
    // console.log(this.props);
    return (
      <React.Fragment>
        <div className="qrFigure">
          <h2>{this.props.data.card.name}</h2>
          <h3>{this.props.data.card.title}</h3>
          <h3>{this.props.data.card.organization}</h3>
          <hr />
          <QRCode
            value={this.props.data.payload}
            size={"80%"}
            bgColor={"#fff"}
            fgColor={"#000"}
            level={"M"}
            includeMargin={false}
            renderAs={"svg"}
            // imageSettings={{
            //   src: "/logo192.png",
            //   x: null,
            //   y: null,
            //   height: 64,
            //   width: 64,
            //   excavate: true,
            // }}
          />
        </div>
      </React.Fragment>
    );
  }
}

//Proptypes
ShowCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ShowCard;
