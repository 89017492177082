import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo192PD.png"; // Tell webpack this JS file uses this image

export default function Header() {
  return (
    <header className="hdrStyle">
      <div>
        <a href="https://platondevs.nl">
          <img
            src={logo}
            width="25%"
            height="auto"
            className="logo"
            alt="PlatonDevs logo"
          />
        </a>
        <h1>QR Business Card</h1>
      </div>
      <Link to="/" className="lnk">
        Home
      </Link>
      <Link to="/create" className="lnk">
        Create Card
      </Link>
      <Link to="/about" className="lnk">
        About
      </Link>
    </header>
  );
}
