import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

export class CreateCard extends Component {
  state = {
    name: "",
    surname: "",
    title: "",
    organization: "",
    url: "",
    email: "",
    telephone: "",
    addressStreet: "",
    addressCity: "",
    addressPO: "",
    addressCountry: "",
    toMain: false,
  };

  onSubmit = (e) => {
    e.preventDefault();
    var payload; //console.log("state:", this.state);
    if (this.state.name === "") {
      payload = ["Fill the required data!"];
    } else {
      payload = [
        "BEGIN:VCARD",
        "VERSION:3.0",
        "N:" + this.state.surname + ";" + this.state.name,
        "FN:" + this.state.name + " " + this.state.surname,
        this.state.organization === ""
          ? null
          : "ORG:" + this.state.organization,
        this.state.title === "" ? null : "TITLE:" + this.state.title,
        this.state.url === "" ? null : "URL:" + this.state.url,
        this.state.email === "" ? null : "EMAIL:" + this.state.email,
        this.state.telephone === ""
          ? null
          : "TEL;TYPE=voice,work,pref:" + this.state.telephone,
        this.state.addressStreet === ""
          ? null
          : "ADR;TYPE=WORK,postal,parcel:;;" +
            this.state.addressStreet +
            ";" +
            this.state.addressCity +
            ";" +
            this.state.addressPO +
            ";" +
            this.state.addressCountr,
        "END:VCARD",
      ];
    }
    //remove nulls
    payload = payload.filter((x) => x);
    this.props.createCard({
      payload: payload.join("\n"),
      card: {
        name: this.state.name + " " + this.state.surname,
        title: this.state.title,
        organization: this.state.organization,
      },
    });
    this.setState(() => ({ toMain: true }));
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.state.toMain === true) {
      return <Redirect to="/" />;
    }
    return (
      <form onSubmit={this.onSubmit} className="vcardForm">
        <div className="qrFigure">
          <label>Name</label>
          <input
            type="text"
            name="name"
            placeholder="Platon"
            value={this.state.name}
            onChange={this.onChange}
          />
        </div>
        <div>
          <label>Surname</label>
          <input
            type="text"
            name="surname"
            placeholder=""
            value={this.state.surname}
            onChange={this.onChange}
          />
        </div>
        <div>
          <label>Title</label>
          <input
            type="text"
            name="title"
            placeholder="Administrator"
            value={this.state.title}
            onChange={this.onChange}
          />
        </div>
        <div>
          <label>Organization</label>
          <input
            type="text"
            name="organization"
            placeholder="PlatonDevs"
            value={this.state.organization}
            onChange={this.onChange}
          />
        </div>
        <div>
          <label>Website</label>
          <input
            type="text"
            name="url"
            placeholder="https://www.platondevs.nl/"
            value={this.state.url}
            onChange={this.onChange}
          />
        </div>
        <div>
          <label>Email Address</label>
          <input
            type="text"
            name="email"
            placeholder="admin@platondevs.nl"
            value={this.state.email}
            onChange={this.onChange}
          />
        </div>
        <div>
          <label>Phone Number</label>
          <input
            type="text"
            name="telephone"
            placeholder="+616 123456"
            value={this.state.telephone}
            onChange={this.onChange}
          />
        </div>
        <hr />

        <div>
          <label>Street</label>
          <input
            type="text"
            name="addressStreet"
            placeholder="Wallstrasse 123"
            value={this.state.addressStreet}
            onChange={this.onChange}
          />
        </div>
        <div>
          <label>City</label>
          <input
            type="text"
            name="addressCity"
            placeholder="Amsterdam"
            value={this.state.addressCity}
            onChange={this.onChange}
          />
        </div>
        <div>
          <label>Post Code</label>
          <input
            type="text"
            name="addressPO"
            placeholder="12345"
            value={this.state.addressPO}
            onChange={this.onChange}
          />
        </div>
        <div>
          <label>Country</label>
          <input
            type="text"
            name="addressCountry"
            placeholder="Netherlands"
            value={this.state.addressCountry}
            onChange={this.onChange}
          />
        </div>
        <hr />
        <input type="submit" value="Submit" className="btn" />
      </form>
    );
  }
}

//Proptypes
CreateCard.propTypes = {
  createCard: PropTypes.func.isRequired,
};

export default CreateCard;

// BEGIN:VCARD
// VERSION:3.0
// N:Lastname;Surname
// FN:Displayname
// ORG:EVenX
// URL:http://www.evenx.com/
// EMAIL:info@evenx.com
// TEL;TYPE=voice,work,pref:+49 1234 56788
// ADR;TYPE=intl,work,postal,parcel:;;Wallstr. 1;Berlin;;12345;Germany
// END:VCARD
