import React, { Component } from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";
// import { v4 as uuidv4 } from "uuid";
import Header from "./components/layout/Header";
import ShowCard from "./components/ShowCard";
import CreateCard from "./components/CreateCard";
import About from "./components/pages/About";
import ReactGA from "react-ga";
import "./App.css";

ReactGA.initialize("UA-163688781-1");

class App extends Component {
  state = {
    payload: "",
    card: {},
  };

  componentDidMount() {
    var payload = localStorage.getItem("payload");
    var card = localStorage.getItem("card");
    if (payload === null) payload = "https://platondevs.nl";
    if (card === null) {
      card = {
        name: "PlatonDevs ",
        title: "Administrator",
        organization: "Platondevs.nl",
      };
    } else card = JSON.parse(card);
    this.setState({ payload, card });
  }

  createCard = (data) => {
    console.log(data);
    this.setState({ payload: data.payload, card: data.card });
    localStorage.setItem("payload", data.payload);
    localStorage.setItem("card", JSON.stringify(data.card));
  };

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
      <Router>
        <div className="App">
          <div className="container">
            <Header />
            <Route
              exact
              path="/"
              render={(props) => (
                <React.Fragment>
                  <ShowCard data={this.state} />
                </React.Fragment>
              )}
            />
            <Route path="/about" component={About} />
            <Route
              path="/create"
              render={(props) => (
                <React.Fragment>
                  <CreateCard createCard={this.createCard} />
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <p>
          By <a href="https://platondevs.nl">platondevs.nl</a>
        </p>
      </Router>
    );
  }
}

export default App;
